import socialWorker from '../../images/social_worker.png';
export default function SocialWorker(){
    const service = {
    id: "SocialWorker",
    title: 'Social Worker',
    description: 'Social workers assist in counseling, community resources, assessment of living conditions and financial concerns.',
    image: socialWorker
    }
    return (
     <div className="mb-4">
     <img src={service.image} alt={service.title} />
     <div>
     <h5>{service.title}</h5>
     <p>{service.description}</p>
     </div>
     </div>
      );
}