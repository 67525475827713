import skilledNursing from '../../images/skilled_nursing.png';
export default function SkilledNursing(){
    const service = {
    id: "SkilledNursing",
    title: 'Skilled Nursing',
    description: 'Our licensed nurses provide medication management, intravenous therapy, injections, wound care, catheter care, and monitoring vital signs.',
    image: skilledNursing
    }
    return (
     <div className="mb-4">
     <img src={service.image} alt={service.title} />
     <div>
     <h5>{service.title}</h5>
     <p>{service.description}</p>
     </div>
     </div>
      );
}