import React from 'react';
import {useNavigate} from 'react-router-dom';
import './Services.css';
import { Slide } from 'react-awesome-reveal';
import skilledNursing from '../../images/skilled_nursing.png';
import physicalTherapy from '../../images/physical_therapy.png';
import occupationalTherapy from '../../images/occupational_therapy.png';
import speechTherapy from '../../images/speech_therapy.png';
import homeHealthAide from '../../images/home_health_aide.png';
import socialWorker from '../../images/social_worker.png';
//import personalAttendant from '../../images/personal_Attendant.png';

const servicesInfo = [
  {
    id: "SkilledNursing",
    title: 'Skilled Nursing',
    description: 'Our licensed nurses provide medication management, intravenous therapy, injections, wound care, catheter care, and monitoring vital signs.',
    image: skilledNursing
  },
  {
    id: "PhysicalTherapy",
    title: 'Physical Therapy',
    description: 'We provide treatment to injury and disease to improve physical actions by providing appropriate exercise, pain management, and therapeutic massage.',
    image: physicalTherapy
  },
  {
    id: "OccupationalTherapy",
    title: 'Occupational Therapy',
    description: 'We help people who has difficulty to perform some daily activities due to injury, illness or disability, by therapeutic use and providing assistive devices.',
    image: occupationalTherapy
  },
  {
    id: "SpeechTherapy",
    title: 'Speech Therapy',
    description: 'We treat patients with difficulty in communicating and other related conditions.',
    image: speechTherapy
  },
  {
    id: "HomeHealthAide",
    title: 'Home Health Aide',
    description: 'Our Home health Aides provide assistance with activities of daily living. They work under the supervision of a nurse or other healthcare providers.',
    image: homeHealthAide
  },
  {
    id: "SocialWorker",
    title: 'Social Worker',
    description: 'Social workers assist in counseling, community resources, assessment of living conditions and financial concerns.',
    image: socialWorker
  }
];

// function ServiceCard({ title, description, image }) {
//   return (
//     <div className="col-12 col-sm-6 col-lg-4 d-flex align-items-stretch">
//       <Slide triggerOnce>
//         <div className="card service-card mb-4">
//           <img src={image} className="card-img-top img-fluid" alt={title} />
//           <div className="card-body d-flex flex-column">
//             <h5 className="card-title">{title}</h5>
//             <p className="card-text flex-grow-1">{description}</p>
//           </div>
//         </div>
//       </Slide>
//     </div>
//   );
// }

// function Services() {
//   return (
//     <div className="container py-5">
//       <div className="row">
//         {servicesInfo.map((service, index) => (
//           <ServiceCard key={index} {...service}/>
//         ))}
//       </div>
//     </div>
//   );
// }



function Services() {
  const navigate = useNavigate();
  return (
    <div className="container py-5">
      <div className="row">
        {servicesInfo.map((service) => (
          <div
            key={service.id} style={{cursor:'pointer'}}
            className="col-12 col-sm-6 col-lg-4 d-flex align-items-stretch"
            onClick={() => navigate(`/services/${service.id}`)}
          >
            <Slide triggerOnce>
              <div className="card service-card mb-4">
                <img src={service.image} className="card-img-top img-fluid" alt={service.title} />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{service.title}</h5>
                  <p className="card-text flex-grow-1">{service.description}</p>
                </div>
              </div>
            </Slide>
          </div>
        ))}
      </div>
    </div>
  );
}


export default Services;


