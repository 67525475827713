import physicalTherapy from '../../images/physical_therapy.png';
export default function PhysicalTherapy(){
    const service = {
    id: "PhysicalTherapy",
    title: 'Physical Therapy',
    description: 'We provide treatment to injury and disease to improve physical actions by providing appropriate exercise, pain management, and therapeutic massage.',
    image: physicalTherapy
    }
    return (
     <div className="mb-4">
     <img src={service.image} alt={service.title} />
     <div>
     <h5>{service.title}</h5>
     <p>{service.description}</p>
     </div>
     </div>
      );
}