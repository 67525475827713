import occupationalTherapy from '../../images/occupational_therapy.png';
export default function OccupationalTherapy(){
    const service = {
    id: "OccupationalTherapy",
    title: 'Occupational Therapy',
    description: 'We help people who has difficulty to perform some daily activities due to injury, illness or disability, by therapeutic use and providing assistive devices.',
    image: occupationalTherapy
    }
    return (
     <div className="mb-4">
     <img src={service.image} alt={service.title} />
     <div>
     <h5>{service.title}</h5>
     <p>{service.description}</p>
     </div>
     </div>
      );
}