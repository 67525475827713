import homeHealthAide from '../../images/home_health_aide.png';
export default function HomeHealthAide(){
    const service = {
    id: "HomeHealthAide",
    title: 'Home Health Aide',
    description: 'Our Home health Aides provide assistance with activities of daily living. They work under the supervision of a nurse or other healthcare providers.',
    image: homeHealthAide
    }
    return (
     <div className="mb-4">
     <img src={service.image} alt={service.title} />
     <div>
     <h5>{service.title}</h5>
     <p>{service.description}</p>
     </div>
     </div>
      );
}